import React from 'react';
import { LinkList } from './LinkList';

/*
    Hvis pagen er listet som referanse i kategorien
    Da er den kategori parent.
    Kan da liste ut lenker til andre poster i kategorien.    
*/

export const CategoryPageLinks = ({ data }) => (
  <>
    {data.length === 0 ? (
      <></>
    ) : (
      <LinkList data={data} heading="Relaterte artikler" />
    )}
  </>
);
