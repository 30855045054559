/* eslint-disable camelcase */
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Typography } from '@mui/material';
import { options } from '../utils/richTextOptions';
import TOC from '../components/TOC';
import { CategoryPageLinks } from '../components/CategoryPageLinks';
import styled from '@emotion/styled';

const isCategoryParent = (pageSlug, categoryParentSlug) => {
  return pageSlug === categoryParentSlug;
};
const STypography = styled(Typography)`
  margin-top: var(--medium);
`;
const Page = ({ data }) => {
  const {
    metaDescription,
    metaTitle,
    pageTitle,
    richTextContent,
    slug,
    category,
    // eslint-disable-next-line camelcase
    categoryReference: { review_page },
  } = data.contentfulPage;

  const catSlug = category && category[0] && category[0].pageReference.slug;

  return (
    <Layout>
      <SEO metaDescription={metaDescription} title={metaTitle} />
      <STypography variant="h4" component="h1">
        {pageTitle}
      </STypography>
      <TOC />
      {renderRichText(richTextContent, options)}

      {isCategoryParent(slug, catSlug) && review_page && (
        <CategoryPageLinks data={review_page.filter(p => p.slug !== slug)} />
      )}
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      __typename
      contentful_id
      metaTitle
      metaDescription
      pageTitle
      slug
      richTextContent {
        raw
        references {
          __typename
          # ... on ContentfulCalculatorPage {
          #   id
          #   slug
          #   contentful_id
          # }
          # ... on ContentfulPage {
          #   id
          #   slug
          #   contentful_id
          # }
          ... on ContentfulCallToActionButton {
            __typename
            text
            to
            contentful_id
          }
          ... on ContentfulReviewPage {
            id
            slug
            contentful_id
          }
          ... on ContentfulImage {
            __typename
            contentful_id
            altTag
            url {
              url
            }
            image {
              gatsbyImageData(width: 1600)
            }
          }
        }
      }
      category {
        pageReference {
          slug
        }
      }
      categoryReference {
        review_page {
          slug
          shortName
          contentful_id
          __typename
        }
      }
    }
  }
`;

export default Page;
